import React, { createContext, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import LandingPage from './pages/screen/landing';
import { history } from './components/history';
import QuestionPage from './pages/screen/question';
import ResultPage from './pages/screen/result';
import ResponsePage from './pages/screen/response';
import CodeScreen from './pages/codescreen';
import StartScreen from './pages/host/start';
import ThoughtsScreen from './pages/host/thoughts';
import NameScreen from './pages/user/namescreen';
import AvatarScreen from './pages/user/avatar';
import WaitScreen from './pages/user/wait';
import StudentTought from './pages/user/thoughts';

import { Global } from './common/global';
import useEventEmitter from './common/event-emitter';
import FinishScreen from './pages/user/finish';
import NotFound from './pages/NotFound';
import HomePage from './pages/dashboard/home';
import LoginPage from './pages/dashboard/login';
import Shared from './pages/dashboard/shared';
import ThoughtfullyView from './pages/dashboard/thoughtfully-view';
import RegisterPage from './pages/dashboard/register';
import ProfilePage from './pages/dashboard/profile';
import AccountPage from './pages/dashboard/account';
import Teams from './pages/dashboard/teams';
import { getActivityInfo, getProfile, isAuthenticated } from './common/api';
import HelpPage from './pages/dashboard/help';
import FAQPage from './pages/dashboard/faq';
import RecoverPage from './pages/dashboard/recover';
import VerifyPage from './pages/dashboard/verify';
import NewPasswordPage from './pages/dashboard/newPassword';


import { GoogleOAuthProvider } from '@react-oauth/google';
import Reports from './pages/dashboard/reports';
import ReportSessions from './pages/dashboard/report-sessions';
import ReportSessionDetail from './pages/dashboard/report-session-details';

let isFirstRouting=true;

let _path = window.location.pathname;
let _type = _path.includes('screen') ? 'screen' : _path.includes('host') ? 'host' : _path.includes('user') ? 'user' : _path.includes('dashboard')?'dashboard':'user';

const gameID = _path.split(`${_type}/`).length > 1 ? _path.split(`${_type}/`)[1].split("/")[0] : '';

const isScreen = _path.includes('screen');
Global.deviceType = _type;
Global.gameID = gameID;
Global.isScreen = isScreen;




const App = () => {
  const [profileInfo, setProfileInfo] = useState(null);
  const [gameData, setGameData] = useState(null);
  const updateActivityName = (data) => {
    setGameData(data);
  };

  const { emitEvent, addEventListener, removeEventListener } = useEventEmitter();
  Global.emitEvent = emitEvent;
  Global.addEventListener = addEventListener;
  Global.removeEventListener = removeEventListener;

  useEffect(() => {
   
    const fetchProfileInfo = async () => {
      
      const profile = await getProfile();
      (profile.isDarkTheme != undefined) &&localStorage.setItem('darkMode', profile.isDarkTheme);
      setProfileInfo(profile);
    };
    fetchProfileInfo();

    isFirstRouting= false;
    // Global.addEventListener("common:game_state_update", updateGameState);
    Global.addEventListener("activity:update_name", updateActivityName);

    return () => {
      Global.removeEventListener("activity:update_name", updateActivityName);

    }


  }, []);

 


  return (
    <Router history={history}>
      <Switch>
        
        <PrivateRoute path="/dashboard" exact component={HomePage} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/tutorial/:tutorialIndex" exact component={HomePage} matchedState="" profileData={profileInfo} tutorial={true}/>
        <PrivateRoute path="/dashboard/shared" exact component={Shared} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/shared/tutorial/:tutorialIndex" exact component={Shared} matchedState="" profileData={profileInfo} tutorial={true}/>
        <PrivateRoute path="/dashboard/teams" exact component={Teams} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/teams/tutorial/:tutorialIndex" exact component={Teams} matchedState="" profileData={profileInfo} tutorial={true}/>
        <PrivateRoute path="/dashboard/thoughtfully/create" exact component={ThoughtfullyView} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/thoughtfully/create/tutorial/:tutorialIndex" exact component={ThoughtfullyView} matchedState="" profileData={profileInfo} tutorial={true}/>
        <PrivateRoute path="/dashboard/thoughtfully/:id/view" exact component={ThoughtfullyView} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/thoughtfully/:id/edit" exact component={ThoughtfullyView} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/help" exact component={HelpPage} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/faq" exact component={FAQPage} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/reports" exact component={Reports} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/reports/tutorial/:tutorialIndex" exact component={Reports} matchedState="" profileData={profileInfo} tutorial={true}/>
        <PrivateRoute path="/dashboard/reports/:thoughtfullyID/sessions" exact component={ReportSessions} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/reports/:thoughtfullyID/sessions/:sessionID" exact component={ReportSessionDetail} matchedState="" profileData={profileInfo}/>


        

        <Route path="/dashboard/login" exact render={(props) => <LoginPage {...props} matchedState="" profileData={profileInfo} />} />        
        <Route path="/dashboard/register" exact render={(props) => <RegisterPage {...props} matchedState="" profileData={profileInfo} />} />        
        <Route path="/dashboard/recover" exact component={RecoverPage} matchedState="" />
        <Route path="/dashboard/verify" exact component={VerifyPage} matchedState="" />
        <Route path="/dashboard/:id/newPassword" exact component={NewPasswordPage} matchedState="" />
        <PrivateRoute path="/dashboard/settings/" exact component={AccountPage} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/settings/profile" exact component={ProfilePage} matchedState="" profileData={profileInfo}/>
        <PrivateRoute path="/dashboard/settings/account" exact component={AccountPage} matchedState="" profileData={profileInfo}/>

        <PrivateRoute path="/screen/:id/" exact component={LandingPage} matchedState="lobby" gameData={gameData}/>
        <PrivateRoute path="/screen/:id/lobby" exact component={LandingPage} matchedState="lobby" gameData={gameData}/>
        <PrivateRoute path="/screen/:id/question" exact component={QuestionPage} matchedState="question" gameData={gameData}/>
        <PrivateRoute path="/screen/:id/result" exact component={ResultPage} matchedState="result" gameData={gameData}/>
        <PrivateRoute path="/screen/:id/response" exact component={ResponsePage} matchedState="response" gameData={gameData}/>
        <PrivateRoute path="/host/:id/code" exact component={CodeScreen} userType="host" matchedState=""/>
        <PrivateRoute path="/host/:id/start" exact component={StartScreen} matchedState="start" />
        <PrivateRoute path="/host/:id/thoughts" exact component={ThoughtsScreen} matchedState="thoughts" />

        <PrivateRoute path="/user/:id/name" exact component={NameScreen} userType="user" matchedState="" />
        <PrivateRoute path="/user/:id/avatar" exact component={AvatarScreen} userType="user" matchedState="" />
        <PrivateRoute path="/user/:id/wait" exact component={WaitScreen} userType="user" matchedState="wait" />
        <PrivateRoute path="/user/:id/thought" exact component={StudentTought} matchedState="thought" />
        <PrivateRoute path="/user/:id/finish" exact component={FinishScreen} matchedState="finish" />
        <PrivateRoute path="/user" exact component={CodeScreen} matchedState=""/>
        <PrivateRoute path="/" exact component={CodeScreen} matchedState=""/>  
        <PrivateRoute path="*" component={NotFound} matchedState="" />
      </Switch>
    </Router>
  );
};

const PrivateRoute = ({ component: Component, matchedState, ...rest }) => {




  const [gameState, setGameState] = useState({
    'screenPage': 'lobby',
    'hostPage': 'start',
    'userPage': 'wait',
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authorisationChecked, setAuthorisationChecked] = useState(false);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);



  const updateGameState = (data) => {
    setGameState(data);
  };

  // const lastRoute = useLastRoute();
  useEffect(() => {
    const checkAuthentication = async () => {
      const authenticationChk = await isAuthenticated();
      setIsLoggedIn(authenticationChk['loggedIn']);
      setIsSubscribed(authenticationChk['subscribed']);
      Global.isSubscribed=authenticationChk['subscribed'];
      if (!authenticationChk['loggedIn'] && (_type !== "user" && _type !== "host")) {
        setIsAuthorised(false);
      } else {
        setIsAuthorised(true);
        // Update profile info as well if required
      }
      setAuthorisationChecked(true)
    };

    checkAuthentication();
    Global.addEventListener("common:game_state_update", updateGameState);
    return () => {
      Global.removeEventListener("common:game_state_update", updateGameState);
    };
  }, []);
  if(!authorisationChecked){
    return false;
  }else{
    return (
      <Route
        {...rest}
        render={(props) =>
          
         isAuthorised ? (
            (matchedState === gameState[`${_type}Page`] || matchedState === "" || (Global.canShowWait && gameState[`${_type}Page`] === "thought" && _type === "user")) ?
              (Global.canShowWait && gameState[`${_type}Page`] === "thoughts" && _type === "user") ?
                <WaitScreen {...props} {...rest} {...gameState} /> :
                <Component {...props} {...rest} {...gameState} />
              :
              <Redirect to={`/${_type}/${Global.gameID}/${gameState[`${_type}Page`]}`} />
          ) : (
            <Redirect to={`/dashboard/login`} />
          )
        }
      />
    );
  }
  
};
if(localStorage.getItem('darkMode') === null){
  localStorage.setItem('darkMode', false)
}

if((_type=='user' || _type == 'screen') && gameID){
  const res=await getActivityInfo({
    id: gameID
  });
  Global.gameMode= res.data.mode;

  Global.studentData.teacherId= res.data.teacherId;
  Global.studentData.activityId=res.data.activityId;
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <App />
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
