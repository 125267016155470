import React, { useState, useEffect } from 'react'
import './style.scss'
import Button from '../button';
import { history } from '../../history';

const Tutorial = (props) => {
    const [darkModeClass, setdarkModeClass ]= useState(
        localStorage.getItem('darkMode') == 'true'?'darkMode':''
      )
      const [tutorialIndex, setTutorialIndex]= useState(parseInt(props.tutorialIndex)-1);
      const [iconClass, setIconClass]= useState('');
      
      const [tutorialContent, setTutorialContent]= useState([
        {
            "title": "OVERVIEW",
            "desc": "Thoughtfully is a web app designed to facilitate interactive learning and engagement in educational settings. It allows hosts to create and manage live surveys and feedback sessions with users, making it easier to gauge understanding and gather insights in real-time.",
            "arrow":"left_top",
            'hightlight':[]
        },
      {
            "title": "CREATE THOUGHTFULLY",
            "desc": "Click “Create New Thoughtfully” on your dashboard.",
            "arrow":"left_top",
            'highlight':['#thoughtfully_create']
        } ,
        {
            "title": "CREATE THOUGHTFULLY",
            "desc": `
            1- Fill in the Fields 
            2- Assign the Thoughtfully to a Team to automatically share it with all your Team Members.
            3- Add Your prompts and click “Add” to save them.
            `,
            "arrow":"left_top",
            'highlight':['#thoughtfully_name','.addBtn', '.question_group', '#team_addition']
        },
        {
            "title": "CREATE THOUGHTFULLY",
            "desc": `
            1- Select between Anonymous responses or having Participants’ names displayed.
            2- Set Default Time for each question as needed.
            3- Add your Thoughtfully to a category for better classification and sorting.
            `,
            "arrow":"top_left",
            'highlight':['.time_right', '.time_left', '#category_addition']
        },
        {
            "title": "START THOUGHTFULLY",
            "desc": `
            1- Click "Go Live".
            2- Wait for the video to finish, then click "Host Live".
            3- Scan the QR code using your phone to control the game—only you should have instructor control, so keep this QR code a secret.
            4- Once the Thoughtfully is live, players can scan the new QR code displayed on the screen to join.
            `,
            "arrow":"top_right",
            'highlight':['.live_btn', '#side_menu_home']
        },
        {
            "title": "TEAMS",
            "desc": "Teams are groups where Thoughtfullys can be shared with all members. This feature helps you collaborate and manage Thoughtfullys more effectively.",
            "arrow":"top_left",
            'highlight':['#side_menu_teams']
        },
        {
            "title": "TEAMS",
            "desc": `
            1- Click “Create a Team”
            2- Enter a name for your team.
            3- Enter the emails of the people you want to include in your team. They don't need to have a Thoughtfully account yet!
            4- Click "Add" to save your team members.
            5- Click “Create" to finalise the team and send out invitations.
            `,
            "arrow":"top_left",
            'highlight':['.team_mini', '#create_team', '#side_menu_teams']
        },
        {
            "title": "SHARED WITH ME",
            "desc": `
            Shared with me shows all Thoughtfullys shared with you:

            1- Team Shared: Thoughtfullys shared with your team.
            2- Individually Shared: Thoughtfullys shared directly with you.

            For both types, you may have View Only or Edit access.
            `,
            "arrow":"top_left",
            'highlight':['.thoughtfully_mini', '#side_menu_shared']
        },
        {
            "title": "REPORTS",
            "desc": `
            Reports offer detailed analytics from your past Thoughtfullys. Get insights on participant engagement, response distributions, and key data points.
            `,
            "arrow":"top_left",
            'highlight':['.reports_mini','#side_menu_reports']
        },
        {
            "title": "READY TO GET STARTED?",
            "desc": `
            Remember: Teach Thoughtfully, Reflect Thoughtfully, and Communicate Thoughtfully.
            `,
            "arrow":"top_left",
            'highlight':[]
        }
      ])

     
      const skipTutorial= () => {
       location.href='/dashboard';
      }
      const onResize= (v,manual, check) => {
        let checkIndex= manual?check:tutorialIndex;

        for(let i=1;i<=4;i++){
            document.querySelector(`#mascot${i}`).classList.remove('active')
        }
        if(checkIndex == 0){
            if(document.querySelector(".mascot")){
                const box= document.querySelector(".mascot").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x-box.width-120}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+40}px`;
                document.querySelector(`#mascot1`).classList.add('active')
            }

        }
        if(checkIndex == 1){
            if(document.querySelector("#thoughtfully_create")){
                const box= document.querySelector("#thoughtfully_create").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x-315}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+box.height+40}px`
            }
      
        }
        if(checkIndex == 2){
            if(document.querySelector(".mascot")){
                const box= document.querySelector(".mascot").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x-box.width-70}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+60}px`
                document.querySelector(`#mascot2`).classList.add('active')
            }
       
        }
        if(checkIndex == 3){
            if(document.querySelector(".mascot")){
                const box= document.querySelector(".mascot").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x-box.width-90}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+40}px`
                document.querySelector(`#mascot2`).classList.add('active')
            }
         
        }
        if(checkIndex == 4){
            if(document.querySelector(".mascot")){
                const box= document.querySelector(".mascot").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x-box.width-90}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+20}px`
                document.querySelector(`#mascot3`).classList.add('active')
            }
          
        }
        if(checkIndex == 5){
            if(document.querySelector("#side_menu_teams")){
                const box= document.querySelector("#side_menu_teams").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x+box.width+50}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y-20}px`
            }
         
        }
        if(checkIndex == 6){
            if(document.querySelector(".team_mini")){
                const box= document.querySelector(".team_mini").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x/* +box.width+150 */}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+100}px`
            }
           
        }
        if(checkIndex == 7){
            if(document.querySelector(".thoughtfully_mini")){
                const box= document.querySelector(".thoughtfully_mini").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x/* +box.width */}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+150}px`
            }
     
        }
        if(checkIndex == 8){
            if(document.querySelector(".reports_mini")){
                const box= document.querySelector(".reports_mini").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+150}px`
            }
         
        }
        if(checkIndex == 9){
            if(document.querySelector(".mascot")){
                const box= document.querySelector(".mascot").getBoundingClientRect();
                document.querySelector(".tutorial_popup").style.left=`${box.x-box.width-90}px`;
                document.querySelector(".tutorial_popup").style.top=`${box.y+40}px`
                document.querySelector(`#mascot4`).classList.add('active')
            }
  
        }
        
    }
  useEffect(() => {
    localStorage.setItem('tutorialShown', true);
    window.addEventListener("resize", onResize);
    let TO1= setTimeout(() => {
        onResize();
    }, 0)
    let TO2= setTimeout(() => {
        onResize();
    }, 100)
    let TO3= setTimeout(() => {
        onResize();
    }, 600)
    let TO4= setTimeout(() => {
        onResize();
    }, 2000)
    return () => {
        clearTimeout(TO1);
        clearTimeout(TO2);
        clearTimeout(TO3);
        clearTimeout(TO4);

        window.removeEventListener("resize", onResize)
    };
  }, [tutorialIndex]);
  const hightlightItems= (highlightIndex) => {
    setTimeout(() => {
        tutorialContent[highlightIndex] && tutorialContent[highlightIndex]['highlight'] && tutorialContent[highlightIndex]['highlight'].forEach((highlight)=> {
            document.querySelector(highlight) && document.querySelector(highlight).classList.add('bringToTop')
            document.querySelector(highlight) && document.querySelector(highlight).classList.add('active')
        })
    }, 0)
  }
  const removeHighlight= (highlightIndex) => {
    tutorialContent[highlightIndex] && tutorialContent[highlightIndex]['highlight'] && tutorialContent[highlightIndex]['highlight'].forEach((highlight)=> {
        document.querySelector(highlight) && document.querySelector(highlight).classList.remove('bringToTop')
        document.querySelector(highlight) && document.querySelector(highlight).classList.remove('active')
    })
  }
  const moveToPrev= () => {
    removeHighlight(tutorialIndex);
    if(tutorialIndex<1){
        skipTutorial();
    }
    setTutorialIndex(tutorialIndex-1);
    onResize(null, true, tutorialIndex-1);
    hightlightItems(tutorialIndex-1);

    navigateTutorial(tutorialIndex-1)
  }
  const moveToNext= () => {
    removeHighlight(tutorialIndex);
    if(tutorialIndex>=tutorialContent.length-1){
        skipTutorial();
    }

   
    setTutorialIndex(tutorialIndex+1);
    onResize(null, true, tutorialIndex+1);
    hightlightItems(tutorialIndex+1);

    navigateTutorial(tutorialIndex+1)
}
const navigateTutorial= (val) => {
    if(val == 1){
        history.push(`/dashboard/tutorial/2`)
    }
    if(val == 2){
        history.push(`/dashboard/thoughtfully/create/tutorial/3`)
    }
    if(val == 3){
        history.push(`/dashboard/thoughtfully/create/tutorial/4`)
    }
    if(val == 4){
        history.push(`/dashboard/tutorial/5`)
    }
    if(val == 5){
        history.push(`/dashboard/teams/tutorial/6`)
    }
    if(val == 6){
        history.push(`/dashboard/teams/tutorial/7`)
    }
    if(val == 7){
        history.push(`/dashboard/shared/tutorial/8`)
    }
    if(val == 8){
        history.push(`/dashboard/reports/tutorial/9`)
    }
}

  return (
    <div className={`tutorial ${darkModeClass}`}>
        <div className="cover"></div>
        {
            tutorialIndex<=tutorialContent.length-1 &&  <div className="tutorial_popup">
            {
                [0, 2, 3, 4, 9].indexOf(tutorialIndex)!=-1 && <div class="arrow-right-top arrow"></div>
            }
             {
                [1].indexOf(tutorialIndex)!=-1 && <div class="arrow-top-right arrow"></div>
            }
            {
                [5].indexOf(tutorialIndex)!=-1 && <div class="arrow-left-top arrow"></div>
            }
             {
                [6, 7, 8].indexOf(tutorialIndex)!=-1 && <div class="arrow-top-left arrow"></div>
            }
            {
                (tutorialIndex<tutorialContent.length) &&<>
                <div className="title">{tutorialContent[tutorialIndex]['title']}</div>
                <div className="desc" style={{ whiteSpace: 'pre-line' }}>{tutorialContent[tutorialIndex]['desc']}</div>
                <div className="tutorial_footer">
                    <div className="footer_nav">{tutorialIndex+1}/{tutorialContent.length}</div>
                    <div className="right">
                        <Button 
                            className={`${(darkModeClass=='darkMode'?'black':'white_transparent')} minimal-padding regular-text `}
                            style={{padding:'5px 20px'}}
                            value="Skip"
                            onClick={skipTutorial}
                        />
                        <Button 
                            className={`${(darkModeClass=='darkMode'?'black':'white_transparent')} ${tutorialIndex==0?'disabled ':''} minimal-padding regular-text `}
                            style={{padding:'5px 20px'}}
                            value="Back"
                            onClick={moveToPrev}
                        />
                        <Button 
                            className={`${(darkModeClass=='darkMode'?'black':'white_transparent')} minimal-padding regular-text `}
                            style={{padding:'5px 20px'}}
                            value="Next"
                            onClick={moveToNext}
                        />
                    </div>
                </div>
                </>
            }
            
       </div>
        }
      
       <div className="mascot" id="mascot1">
        <img src="/images/dashboard/mascot1.png" alt="" srcset="" />
       </div>
       <div className="mascot" id="mascot2">
        <img src="/images/dashboard/mascot2.png" alt="" srcset="" />
       </div>
       <div className="mascot" id="mascot3">
        <img src="/images/dashboard/mascot3.png" alt="" srcset="" />
       </div>
       <div className="mascot" id="mascot4">
        <img src="/images/dashboard/mascot4.png" alt="" srcset="" />
       </div>
    </div>
  )
}

export default Tutorial
