import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/faq.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import Collapsible from "../../components/collapsible/index";
import Ads from '../../components/dashboard/Ad';
import Copyright from '../../components/dashboard/copyright';

const FAQPage = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
    const [faqs, setFaqs]= useState(
      [
        {
          "title":"General Information",
          "faq":[
              {
                'key':'faq1_1',
                'question':"1- What are the typical uses of Thoughtfully?",
                'explanation':`Thoughtfully is primarily used for in-class discussions on topics that don't have clear right or wrong answers. It utilises a Likert Scale from "Strongly Agree" to "Strongly Disagree," encouraging deep thinking and nuanced responses. Hosts can apply Thoughtfully to any topic to engage users in thoughtful discussions.`
              },
              {
                'key':'faq1_2',
                'question':"2- What's the difference between default time and additional time?",
                'explanation':`Default time is the base time allotted for each prompt. Additional time is an optional extension that can be added on top of the default time for a specific prompt, but it only applies to that individual prompt, not others.`
              },
              {
                'key':'faq1_3',
                'question':"3- Can I change the possible answers away from the Likert Scale?",
                'explanation':`No, the Likert Scale ("Strongly Agree" to "Strongly Disagree") is intentionally kept as the standard to ensure open-ended discussions and to help users appreciate the nuances in various topics. It’s designed to avoid black-and-white answers.`
              },
              {
                'key':'faq1_4',
                'question':"4- How should I phrase my prompts?",
                'explanation':`Prompts should be phrased in a way that they can be answered using the Likert Scale ("Strongly Agree" to "Strongly Disagree"). This is often best achieved by using statements rather than questions.`
              },
          ]
        },
        {
          "title":"Technical and Functional Questions",
          "faq":[
              {
                'key':'faq2_1',
                'question':"1- If I lose host access control over the game, do I have to restart?",
                'explanation':`No, you don't have to restart the game. If you lose host access, simply click this link and enter your session code to regain control. Make sure only you have host control to manage the game effectively.`
              },
              {
                'key':'faq2_2',
                'question':"2- How do I choose a player to share their rationale?",
                'explanation':`You can choose a player to share their rationale only after all responses have been submitted. The selection can be made using the host's phone controller.`
              },
              {
                'key':'faq2_3',
                'question':"3- What device can I use to host Thoughtfully?",
                'explanation':`It’s recommended to use a laptop projected onto a screen for gameplay, while the host should use their personal phone for controlling the game.`
              },
              {
                'key':'faq2_4',
                'question':"4- What device can participants use to join Thoughtfully?",
                'explanation':`Participants should use their personal phones to key in their responses. While tablets and laptops are supported, they are not encouraged for the best user experience.`
              },
              {
                'key':'faq2_5',
                'question':"5- How do I change the settings of a live Thoughtfully session?",
                'explanation':`Once a Thoughtfully is live, settings cannot be changed. To adjust settings, you need to end the current session and modify the Thoughtfully before starting a new session.`
              },
              {
                'key':'faq2_6',
                'question':"6- Can participants join the Thoughtfully session after it has started?",
                'explanation':`Yes, participants can join a session even after it has started by scanning the QR code or entering the session code. Their responses will be recorded from the point they join.`
              }
          ]
        },
        {
          "title":"Management and Moderation",
          "faq":[
              {
                'key':'faq3_1',
                'question':"1- Can I remove a player for using an obscene name?",
                'explanation':`Yes, you can remove a player using the host phone before they join the game. There will be an "X" next to their name that you can click to remove them.`
              },
              {
                'key':'faq3_2',
                'question':"2- Can I skip or replay prompts during a live session?",
                'explanation':`Yes, as the host, you have the ability to skip or replay questions. Use the control panel on your phone to navigate through the questions as needed.`
              },
              {
                'key':'faq3_3',
                'question':"3- What should I do if a participant can't join the Thoughtfully session?",
                'explanation':`Ensure that the participant is using the correct QR code or session code. They should also check their internet connection. If issues persist, try restarting the session or asking them to refresh their browser.`
              },{
                'key':'faq3_4',
                'question':"4- Can I preview a Thoughtfully before going live?",
                'explanation':`Yes, you can preview a Thoughtfully by using the “Preview” option in the “Create Thoughtfully” page. This allows you to see how your prompts will appear and function during the session. This helps ensure everything is set up correctly before you go live.`
              },
          ]
        },
        {
          "title":"User Experience and Data Management",
          "faq":[
              {
                'key':'faq4_1',
                'question':"1- What should I do if a participant accidentally submits the wrong answer?",
                'explanation':`Once an answer is submitted, it cannot be changed. Encourage participants to double-check their answers before submitting. However, they can continue participating in the remaining prompts.`
              },
              {
                'key':'faq4_2',
                'question':"2- Can participants see their responses after the session ends?",
                'explanation':`Participants do not have access to their individual responses after the session ends. Only the host can view and analyze the results through the Reports feature.`
              },,
              {
                'key':'faq4_3',
                'question':"3- Can I duplicate a Thoughtfully for reuse in future sessions?",
                'explanation':`Yes, you can duplicate a Thoughtfully from your dashboard. This allows you to reuse prompts and settings without starting from scratch, saving time when preparing for new sessions.`
              },
          ]
        },
        {
          "title":"Limitations and Constraints",
          "faq":[
              {
                'key':'faq5_1',
                'question':"1- Is there a limit to the number of participants in a Thoughtfully session?",
                'explanation':`The number of participants allowed may vary depending on your plan. For the most accurate information, please refer to your account details or plan specifics. As of 27 August 2024, the maximum participant limit is 100.`
              },
              {
                'key':'faq5_2',
                'question':"2- Can I use multimedia (images, videos) in Thoughtfully prompts?",
                'explanation':`No, you cannot include images and videos in your Thoughtfully prompts at this point in time.`
              },,
              {
                'key':'faq5_3',
                'question':"3- Can I run a Thoughtfully session without an internet connection?",
                'explanation':`No, an internet connection is required to create, manage, and participate in Thoughtfully sessions. Ensure a stable connection to avoid disruptions.`
              },{
                'key':'faq5_4',
                'question':"4- What happens if I accidentally delete a Thoughtfully?",
                'explanation':`Deleted Thoughtfullys cannot be recovered. Make sure to confirm the deletion before proceeding. If you need assistance, contact support for help.`
              },
              {
                'key':'faq5_5',
                'question':"5- Can I integrate Thoughtfully with other tools or platforms?",
                'explanation':`Currently, Thoughtfully does not support direct integrations with other tools. You can export data for use in other applications if you have access to Reports.`
              },,
              {
                'key':'faq5_6',
                'question':"6- How do I invite participants to join a Thoughtfully session?",
                'explanation':`Share the QR code displayed during the live session with participants. They can scan this code using their devices to join the Thoughtfully.`
              },
          ]
        }
      ]
    );
  useEffect(() => {
  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
            <div className={`faq_container ${darkModeClass}`}>
                <SideMenu/>
                <div className="middle_area">
                    <div className="middle_content">
                        <div className="head">
                            <div className="left">
                            <div className="title">FAQs</div>
                            <div className="info">Contact us at info@jalanjourney.com</div>
                            </div>
                        </div>
                        <div className="faq_multiple">
                          {
                            faqs && faqs.map((faqObj, resultIndex) => (
                              <>
                                <div className="title">{faqObj.title}</div>
                                <div className="faq_sec">
                                {faqObj.faq && faqObj.faq.map((faq, resultIndex) => (
                                    <div className='faq'>
                                        <Collapsible title={faq['question']} key={faq['key']}/*  users={faq['users']} */>
                                            {faq['explanation']}
                                        </Collapsible>
                                    </div>
                                    
                                ))}
                                </div>
                              </>
                            ))
                          }
                          
                         
                          
                      
                        </div>
                  
                    </div>
                    <Copyright/>
                </div>
                <Ads darkModeClass={darkModeClass}/>
            </div>
        </DashboardApp>
  );
}

export default FAQPage;