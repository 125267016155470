import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import Button from '../button';
import { getActivityClientID, updateThoughtfullyLiveInfo } from '../../../common/api';
import QRCode from 'qrcode.react';
import ConfirmPopup from '../confirmPopup';

const LivePopup = (props) => {
    const [darkModeClass, setDarkModeClass] = useState(
        localStorage.getItem('darkMode') === 'true' ? 'darkMode' : ''
    );
    const [teacherURL, setTeacherURL] = useState('');
    const [screenURL, setScreenURL] = useState('');
    const [studentURL, setStudentURL] = useState('');
    const [showAd, setShowAd] = useState(props.showAd);
    const [videoCompleted, setVideoCompleted] = useState(false);
    const [showQr, setShowQr] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [liveActivityID, setLiveActivityID] = useState(props.liveActivityID);
    const [clientID, setClientID] = useState(null);
    const [canvasWidth, setCanvasWidth] = useState(
        Math.min(0.2 * window.innerWidth * 1.2, 0.3 * window.innerHeight * 1.0)
    );

    const videoRef = useRef(null);

    const onLiveCreate = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.pause();
        }
        setShowAd(false);
        setShowQr(true);
        fetchClientID();
    };

    const goLiveNow = async () => {
        await updateThoughtfullyLiveInfo(liveActivityID, clientID);
    };

    const showConfirmation = () => {
        setShowAd(false);
        setShowQr(false);
        setShowConfirm(true);
    };

    const hideConfirmation = () => {
        setShowConfirm(false);
        setShowAd(false);
        setShowQr(true);
    };

    const handleResize = () => {
        setCanvasWidth(Math.min(0.2 * window.innerWidth * 1.2, 0.3 * window.innerHeight * 1.0));
    };

    const fetchClientID = async () => {
        const res = await getActivityClientID(liveActivityID);
        setClientID(res['clientID']);

        setTeacherURL(`${window.location.protocol}//${window.location.host}/host/${res['clientID']}/start`);
        setScreenURL(
            `${window.location.protocol}//${window.location.host}/screen/${res['clientID']}/lobby/?clientID=${res['clientID']}`
        );
        setStudentURL(`${window.location.protocol}//${window.location.host}/user/${res['clientID']}/name`);
    };

    useEffect(() => {
        if (showAd) {
            const randomNumber = Math.floor(Math.random() * 7) + 1;
            const videoElement = videoRef.current;
            if (videoElement) {
                videoElement.src = `/videos/Ad${randomNumber}.mp4`;
                videoElement.currentTime = 0;
                videoElement.play();
            }
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [showAd]);

    const handleTimeUpdate = () => {
        if (videoRef.current && videoRef.current.currentTime >= 10) {
            setVideoCompleted(true);
        }
        document.querySelector(".video_progress .bg").style.transform =
        `translateX(${(videoRef.current.currentTime / videoRef.current.duration) * 100-100}%)`;
          };

    if (!showAd && !showQr && !showConfirm) {
        return false;
    } else {
        return (
            <div className={`live_popup ${darkModeClass}`}>
                <div id="live_popup_close" className="close" onClick={props.closeLivePopup}></div>
                <div className="popup_container">
                    {showQr ? (
                        <>
                            <div className="QR_sec">
                                <div className="desc">For host use only</div>
                                <div className="title">USE YOUR PHONE TO SCAN AND CONTROL THE THOUGHTFULLY</div>
                                <div className="code_container">
                                    <QRCode value={teacherURL} size={canvasWidth} />
                                </div>
                                <Button
                                    className={`purple-purple bold-text text-center`}
                                    style={{ borderRadius: '10px', width: '150px' }}
                                    value="PROCEED"
                                    navigateToBlank={screenURL}
                                    onClick={goLiveNow}
                                />
                            </div>
                        </>
                    ) : showConfirm ? (
                        <>
                            <ConfirmPopup
                                isMini={false}
                                onProceed={goLiveNow}
                                onCancel={hideConfirmation}
                                title="Have you scanned the QR code yet?"
                                desc="Please ensure you have scanned the QR code before proceeding. You must log in to moderate the Thoughtfully"
                            />
                        </>
                    ) : (
                        <>
                            <div className="video_sec">
                                <a href="https://jalanjourney.com/" target="_blank" rel="noopener noreferrer">
                                    <video ref={videoRef} onTimeUpdate={handleTimeUpdate} onEnded={() => setVideoCompleted(true)}></video>
                                </a>
                            </div>
                            <Button
                                className={`${!videoCompleted ? 'disabled ' : ''}purple-purple bold-text text-center`}
                                style={{ borderRadius: '10px', width: '150px' }}
                                value="HOST LIVE"
                                onClick={onLiveCreate}
                                disabled={!videoCompleted}
                            />
                        </>
                    )}
                    {
                        showAd && <>
                            <div className="video_progress">
                                <div className="bg"></div>
                            </div>
                        </>
                    }
                    
                </div>
            </div>
        );
    }
};

export default LivePopup;
