import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/verify.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import {doLogin, doRegister, verifyOTP} from '../../common/api';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Button from '../../components/dashboard/button';
import ToggleButton from 'react-toggle-button'
import { history } from '../../components/history'
import IconProfile from '../../components/dashboard/icons/Icon-Profile';

const VerifyPage = (props) => {

    const [otp, setOtp]= useState('');
    const [errorMsg, setErrorMsg]= useState("");
    const [verifyCodeSent, setVerifyCodeSent]= useState(false);
    const [otpCheckActive, setOtpCheckActive] = useState(false);
    const [validData, setValidData]= useState(true);

    const verifyCheck= async() => {
      if(otp.length ==0){
          setErrorMsg("Invalid OTP");
          setValidData(false);
          return false;
      }
      setOtpCheckActive(true);
      setValidData(true);
      const res= await verifyOTP(localStorage.getItem('verifyEmail'), otp);
      setOtpCheckActive(false);
      if(res.data.status !== 200){
          setErrorMsg(res.data.message);
          setValidData(false);
      }else if(res.data.status === 200){
        const res= await doRegister(localStorage.getItem('verifyEmail'));
        if (res.data && res.data.status === 200) {
                    localStorage.setItem('tutorialShown', false);
                    if(!res.data.tutorialShown){
                        location.href='/dashboard/tutorial/1';
                      }else{
                        location.href='/dashboard';
                      }
                } else if (res.data.status === 422) {
                    setErrorMsg("User with same email id exists already.");
                    setValidData(false);
                }
      }
    }
  useEffect(() => {
  
  }, []);

  return (
        <DashboardApp extraClass={"landscape_only"} addHeader={false}>
            <div className="verify_container">
              <div className="left">
                <div className="logo">
                    {
                        localStorage.getItem('darkMode') !== 'true'?<img src="/images/Logo.png" alt="" />:<img src="/images/LogoW.png" alt="" />
                    }
                </div>
              </div>
              <div className="right">
                <div className="verify_area">
                    {
                        verifyCodeSent ? <>
                            <div className="verified_label">
                                A OTP email is sent to you.
                            </div>
                        </>:<>
                          <div className="head">
                                <div className="title">Verify Code</div>
                                <div className="title_sub">An OTP has been sent to your email</div>
                            </div>
                            <div className="content">
                                <div className='field'>
                                    <div className="icon"><IconProfile/></div>
                                    <input
                                        type="text"
                                        name="verify_otp"
                                        id="verify_otp"
                                        value={otp}
                                        onChange={(v)=>{setOtp(v.target.value)}}
                                        placeholder="OTP"
                                        autocomplete="off"
                                    />
                                </div>
                                
                                <div className="buttons">
                                    <Button 
                                        className={`yellow bold-text ${otpCheckActive ? 'disabled' : ''} text-center`}
                                        style={{borderRadius:'30px', padding:'15px 0'}}
                                        value="VERIFY"
                                        onClick={verifyCheck}
                                    />
                                    {
                                        !validData && <div className="otp_error">{errorMsg}</div>
                                    }
                                </div>

                            </div>
                        </>
                    }
                  
                </div>
              </div>
            </div>
        </DashboardApp>
  );
}


export default VerifyPage;