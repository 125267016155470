import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Global } from '../common/global';
import DashboardHeader from '../components/dashboard/header/header';
import BGComponent from './BGComponent';
import RotateCheck from '../components/dashboard/Rotate';

function DashboardApp({ children, ...props}) {
  const [isPortrait, setIsPortrait]= useState(window.innerWidth<850);
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode')!='false');
  const [canUse, setCanUse] = useState(
    location.pathname.indexOf("register")!==-1 || 
    location.pathname.indexOf("login")!==-1 || 
    location.pathname.indexOf("recover")!==-1 || 
    location.pathname.indexOf("newPassword")!==-1 ||
    location.pathname.indexOf("verify")!==-1
  );

  useEffect(() => {
    const onResize= () => {
      setIsPortrait(window.innerWidth<850)
    }
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
      <div className={`${darkMode?'dark ':''}dashboard-app`}>
          <div className='bg_default'>
           
          </div>

        {
          ((props.addHeader===undefined || props.addHeader!==undefined && props.addHeader) && !isPortrait) && <DashboardHeader extraClass={props.extraClass} {...props}/>
        }
        {
          (!isPortrait || canUse) ? <main>{children}</main>:<RotateCheck/>
        }

      </div>
  )
}

DashboardApp.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default DashboardApp
