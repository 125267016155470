import React, { useEffect } from 'react';
import { Global } from './global';

import pako from 'pako';
// const util = require('util');



let client = null;
let room = null;
let host= window.location.host;
const REACT_APP_COLYSEUS_BASE = process.env.NODE_ENV=='development'?process.env.REACT_APP_COLYSEUS_BASE:`ws${location.protocol.split('http')[1]}//${host}`;
const REACT_APP_API_URL= process.env.REACT_APP_API_URL;//(process.env.NODE_ENV=='development' || process.env.NODE_ENV=='production')?process.env.REACT_APP_API_URL:'https://thoughtfully-staging.up.railway.app/';

const ColyseusComponent = (props) => {
    if(props.initClient){
        if(client == null){
            client = new window.Colyseus.Client(REACT_APP_COLYSEUS_BASE);
        }
    }


    useEffect(() => {
      Global.addEventListener("colyseus:send_events", sendEvents);
      Global.addEventListener("colyseus:send_actions", sendActions);
        return () => {
          Global.removeEventListener("colyseus:send_events", sendEvents);
          Global.removeEventListener("colyseus:send_actions", sendActions);


            // destroyGameRoom(); // Clean up when component unmounts
        };
    }, []);

    const sendGameTick = async () => {
        if (!room) return;

       
        // const compressed = await gzipAsync(JSON.stringify({ message: "tick" }));
        const stringMessage = JSON.stringify({ message: "tick" });
        const compressed =  pako.gzip(stringMessage);
        room.send("game_tick", compressed.buffer);
        /* room.send("game_tick", {
            message: "tick",
        }); */
    };
    const checkIfValidCode= async (classID) => {
        try {
            let roomIdToJoin = -1;
            let rooms = await client.getAvailableRooms("gameRoom");
            rooms.forEach((roomInst) => {
                if (String(roomInst.metadata.gameID) === String(Global.gameID)) {
                    roomIdToJoin = roomInst.roomId;
                }
            });
            return roomIdToJoin != -1;
        }catch(err){
            return false;
        }
        return false;
    }
    const connect = async (gameID) => {
        let connectStatus = "";

        try {
            let roomIdToJoin = -1;
            let rooms = await client.getAvailableRooms("gameRoom");

            rooms.forEach((roomInst) => {
                if (String(roomInst.metadata.gameID) === String(Global.gameID)) {
                    roomIdToJoin = roomInst.roomId;
                }
            });
            // if (Global.deviceType === "screen") {
                if (roomIdToJoin !== -1) {
                    room = await client.joinById(roomIdToJoin, {
                        deviceType: String(Global.deviceType),
                        userName: Global.userName,
                        avatarID: Global.avatarID,
                        origin: REACT_APP_API_URL
                    });
                    connectStatus = "success";
                } else {
                    room = await client.create("gameRoom", {
                        gameID: String(Global.gameID),
                        deviceType: String(Global.deviceType),
                        userName: Global.userName,
                        avatarID: Global.avatarID,
                        origin: REACT_APP_API_URL
                    });
                   
                    connectStatus = "success";
                }
  
                onRoomCreated();
                if(Global.deviceType == 'user'){
                    Global.studentData.studentId=room.sessionId;
                    Global.studentData.sessionId=room.id;
                   
                }else if(Global.deviceType == 'screen'){
                    Global.sessionId=room.id;
                }
               
        } catch (err) {
            connectStatus = "failure";
            if (err.code == -1) {
                alert(`You are not allowed to join this game!`);// \n ${Global.deviceType} is already open on another device.
            }
        }

        return connectStatus;
    };

    const destroyGameRoom = () => {
        if (room) {
            room.removeAllListeners();
            room.leave(true);
            room = null;
        }
    };

    const onRoomCreated = () => {
        if (!room) return;

        room.onMessage("*", async (type, data) => {
          // console.log(type, data,'type, data')
        //   let decompressed = await gunzipAsync(data);
          let  decompressed = await pako.ungzip(data, { to: 'string' });

          decompressed= JSON.parse(decompressed.toString())
            if (type === "Actions") {
                onActions(decompressed);
            } else if (type === "Games") {
                onGameEvents(decompressed);
            }
        });

        room.onLeave(onLeave);

        // Global.gameTick = setInterval(sendGameTick, 5000);
        window.gameRoom = room;
        window.addEventListener("beforeunload", () => {
            if (client && room) {
                room.leave().catch(err => console.error("Failed to leave the room:", err));
            }
        })
    };


    const onLeave = async () => {
        if (!room) return;

        clearInterval(Global.gameTick);

        if (Global.gameFinished) return;

        try {
            room = await client.reconnect(room.id, room.sessionId);
        } catch (err) {
            
            showDisconnectedInfo();
        }

        onRoomCreated();
    };
    const showDisconnectedInfo= () => {
        if(Global.deviceType == "user"){
            document.querySelector(".connection_lost_info .profile_img img").src= Global.imgKey;
            document.querySelector(".connection_lost_info .name").innerHTML= Global.userName;
        }else{
            document.querySelector(".connection_lost_info .profile_img").style.display='none';
            document.querySelector(".connection_lost_info .name").style.display='none';
            
        }

        
        document.querySelector(".connection_lost_info").classList.add("active")
        document.querySelector("main").classList.add("disabled")
        document.querySelector(".connection_lost_bg").classList.add("active");

        if(Global.deviceType == "user"){
            document.querySelector("#retryConnection").href= `${window.location.protocol}//${window.location.host}/user/${Global.gameID}/name`;
        }else if(Global.deviceType == "host"){
            document.querySelector("#retryConnection").href= `${window.location.protocol}//${window.location.host}/host/${Global.gameID}/start`;
        }else if(Global.deviceType == "screen"){
            document.querySelector("#retryConnection").href= `${window.location.protocol}//${window.location.host}/screen/${Global.gameID}/lobby`;
        }
        destroyGameRoom();
    }
    const sendEvents = async (data) => {
        if (!room) return;

        // const compressed = await gzipAsync(JSON.stringify(data));
        const stringMessage = JSON.stringify(data);
        const compressed = pako.gzip(stringMessage);
        room.send("game_events", compressed.buffer);
        // room.send("game_events", data);
    };

    const sendActions = async (actionData) => {
        if (!room) return;

        // const compressed = await gzipAsync(JSON.stringify(actionData));
        const stringMessage = JSON.stringify(actionData);
        const compressed = await pako.gzip(stringMessage);
        room.send("action", compressed.buffer);
        // room.send("action", actionData);
    };

    const onActions = (data) => {
        switch (data.message.actionType) {
            case "joystick_action":
                Global.emitEvent("joystick:control_add", data.message.actionData.direction);
                break;
            case "volume_action":
                Global.soundEnabled = data.message.actionData.volume === 0 ? false : true;
                if (Global.soundEnabled) {
                    Global.bgAudio.resume();
                } else {
                    Global.bgAudio.pause();
                }
                break;
            default:
                break;
        }
    };

    const onGameEvents = (data) => {
        switch (data.type) {
            case "student_join":
                Global.deviceType!="user" && Global.emitEvent(`screen:student_join`, data.message);
                break;
            case "assign_random_name":
                Global.userName=data.message.userName;
                Global.studentData.studentName=data.message.userName
                break;
            case "game_state_update":
               Global.emitEvent(`common:game_state_update`, data.message);
               break;
            case "update_question_info":
                Global.emitEvent(`common:question_update_info`, data.message);
                break;
            case "move_to_answer":
                Global.emitEvent(`common:move_to_answer`, data.message);
                break;
            case "game_opinion_updates":
                Global.emitEvent(`common:game_opinion_update`, data.message);
                break;
            case "update_wait_time":
              Global.emitEvent(`time:update_wait_time`, data.message);
              break;
            case "user_left":
                Global.emitEvent("user:leave", data.message);
                break;
            case "update_acitivity_name":
                Global.emitEvent("activity:update_name", data.message);
                break;
            case "user_picked_to_share":
                Global.emitEvent("user:picked_to_share", data.message);
                break;
            case "user_removed":
                document.querySelector(".removed_info .profile_img img").src= data.message.img;
                document.querySelector(".removed_info .name").innerHTML= data.message.name;
                
                document.querySelector(".removed_info").classList.add("active")
                document.querySelector("main").classList.add("disabled")
                document.querySelector(".removed_bg").classList.add("active")
                destroyGameRoom();
                break;
            default:
                break;
        }
    };

    return { connect, checkIfValidCode }; // This component does not render anything visible
};

export default ColyseusComponent;
