import React, { useState, useEffect } from 'react'
import { history } from '../history'
import Session from '../session'
import './style.scss'
import StudentProfileIcon from '../studentProfileIcon'
import { Global } from '../../common/global'
import { useMediaQuery } from 'react-responsive'
import Infos from '../dashboard/Infos'
import QRCode from 'qrcode.react'; // Import qrcode.react
import { Link } from 'react-router-dom/cjs/react-router-dom'

const Footer = (props) => {

  const [isSkipped, setIsSkipped]= useState(false)
  const isPortraitScreen = useMediaQuery({
      query: '(max-width: 1024px) and (max-aspect-ratio: 13/10)'
  });
  const isIPadScreen = useMediaQuery({
    query: '(min-width: 600px) and (max-width: 1024px) and (max-aspect-ratio: 13/10)'
});
const [canvasWidth, setCanvasWidth]= useState(Math.min(.2*window.innerWidth, .3*window.innerHeight));

  const [canShowTerms, setCanShowTerms]= useState(false);
  const [canShowPrivacyPolicy, setCanShowPrivacyPolicy]= useState(false);
  const [canShowCookie, setCanShowCookie]= useState(false);

  useEffect(() => {
    return () => {
     
    };
  }, []);

  return (
    (Global.isScreen || props.showQnFooter) ? ( <>
    <footer className={`footer ${props.extraClass} ${props.showQnFooter?'move_extra':''}`}>
      <div className="footer_container">
      <div className="sec1">
        <div className="code_sec">
            <div className="title">
              <div className="footer_code_title">Use Code</div>
              <div className="footer_code_url">http{location.protocol.split('http')[1]}//{location.host}</div>
            </div>
            <div className="code_container">
            <QRCode value={`${window.location.protocol}//${window.location.host}/user/${Global.gameID}/avatar`} size={65}/>
            </div>
            <div className="code_area">{props.showQnFooter?props.thoughtfullyID:Global.gameID}</div>
          </div>
      </div>
      <div className="sec2">
        <div className="logo">
            <img src="/images/Logo.png" alt="" />
          </div>
      </div>
      </div>
      
    </footer>
    </> ) :( 
    <>
      <footer className={`${props.extraClass} footer ${((props.isStudent || props.canCountDown) && !Global.canShowWait)?'inline':''}`}>
        {
          ((!props.isStudent && !props.canCountDown) || Global.canShowWait)?(
            !props.isThoughts && <>
                {
                  props.type !== "type1"?<div className="title">By using Thoughtfully, you agree to our</div>:<div className="title bold">Create your own Thoughtfully for free at <a href="/dashboard">thoughtfully.tv/dashboard</a></div>
                }
                <div className="links">
                  <u className='link' onClick={setCanShowTerms.bind(this, true)}>Terms</u>
                  <u className='link' onClick={setCanShowPrivacyPolicy.bind(this, true)} id="privacy">Privacy</u>
                  <u className='link' onClick={setCanShowCookie.bind(this, true)}>Cookies Notice</u>
                  
                </div>
            </>
            
          ):(
            <>
              {
                props.isStudent && <div className="profile_sec">
                  <StudentProfileIcon 
                    id={props.id} 
                    imgUrl={props.img} 
                    name={props.name} 
                    showName={true} 
                    className={'full_width'}
                    showPhoto={true} 
                    extraImgStyles={{marginRight: isPortraitScreen?'2vw':'2vh',width: isPortraitScreen?(isIPadScreen?'6vw':'8vw'):'5.5vh',height: isPortraitScreen?(isIPadScreen?'6vw':'8vw'):'5.5vh', backgroundColor:'#684d94'}} 
                    extraNameStyles={{fontSize: isPortraitScreen?(isIPadScreen?'3vw':'4vw'):'3vh',fontFamily:'MontserratRegular'}} 
                    extraProfileStyles={{ borderRadius:'10vw'}}
                  />
                </div>
              }
              
              <div className="timer_sec">Time Left: {props.questionTime}</div>

              {
                !props.isStudent && <div className="skipBtn" onClick={() => {
             
                  Global.emitEvent(
                    "colyseus:send_actions",
                    {
                        "action_type": "skip_question"
                    }
                )
                }}>Skip</div>
              }
            </>
          )
        }
        <Infos 
                canShowTerms={canShowTerms} 
                canShowPrivacyPolicy={canShowPrivacyPolicy} 
                canShowCookie={canShowCookie} 
                setCanShowTerms={setCanShowTerms} 
                setCanShowPrivacyPolicy={setCanShowPrivacyPolicy} 
                setCanShowCookie={setCanShowCookie}
            />
      </footer>
    </> 
    )
  )
}

export default Footer
