import React, {useEffect, useState} from 'react';
import '../../styles/dashboard/reports.scss';
import DashboardApp from '../../containers/dashboard-wrapper';
import SideMenu from '../../components/dashboard/sidemenu';
import { history } from '../../components/history'
import Copyright from '../../components/dashboard/copyright';
import ReportsMini from '../../components/dashboard/reports-mini';
import { Link, NavLink, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getAllActivitySessions, getSessionInfos } from '../../common/api';
import IconLeft from '../../components/dashboard/icons/Icon-ArrowLeft';
import Button from '../../components/dashboard/button';
import SessionsMini from '../../components/dashboard/sessions-mini';
import Collapsible from "../../components/collapsible/index";
import ProgressBar from "@ramonak/react-progress-bar";

const ReportSessionDetail = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
 

  const [searchKey, setSearchKey]= useState('');
  const [activity, setActivity]= useState('');
  const [sessions, setSessions]= useState('');

  const [sessionReport, setSessionReport]= useState([]);

  const { thoughtfullyID,  sessionID} = useParams();
  const formatNumber = (value) => {
    // Check if the value is a number and not NaN
    if (typeof value !== 'number' || isNaN(value)) {
        throw new Error('Invalid input: Not a number');
    }

    // Check if the number is an integer
    if (Number.isInteger(value)) {
        // Return the integer part as a string
        return value.toString();
    } else {
        // Format the number to 11 decimal places and remove trailing zeros
        return value.toFixed(2).replace(/\.?0+$/, '');
    }
}
  useEffect(() => {
        const getSessionInfo= async () => {
            const res= await getSessionInfos(thoughtfullyID, sessionID);  
            setActivity({
                activityName: res.activityName,
                lastLiveAt:res.lastLiveAt
            });
            let report=[]
            if(res && res.questionStudentMap){
              Object.keys(res.questionStudentMap).forEach((qnId) => {
                report.push({
                    'key': qnId,
                    'question': res.questionStudentMap[qnId]['questionText'],
                    'answerDistribution': res.questionStudentMap[qnId]['answerDistribution'],
                    'studentCount': res.questionStudentMap[qnId]['studentCount'],
                    'timeTakenDistribution': res.questionStudentMap[qnId]['timeTakenDistribution'],
                    'students': res.questionStudentMap[qnId]['students']
                })
                
            })
           
            }
            setSessionReport(report);
            
         
        }
        getSessionInfo();
  }, []);



  return (
        <DashboardApp extraClass={"landscape_only"} {...props.profileData}>
            <div className={`report_container ${darkModeClass}`}>
              <SideMenu/>
              <div className="middle_area">
                  <div className="head">
                    <div className="left">
                      <div className="title">REPORTS</div>
                    </div>
                    {/* <div className="right">
                      <input type="text" name="reports_search" id="reports_search" placeholder="Search..." value={searchKey} onChange={(v) => { setSearchKey(v.target.value); }}  autocomplete="off"/>
                   
                    </div> */}
                  </div>
                  <div className="middle_content">
                    <div className="activity_head">
                      <div className="left">
                        <div className="activity_title">{activity.activityName} <span className="sessionID">({sessionID})</span></div>
                        <div className="activity_info">Last Live: <span className="bold">{activity.lastLiveAt/* new Date(activity.lastLiveAt).toUTCString().replace('GMT', '').trim() */}</span></div>
                      </div>
                      <div className="right">
                        <div className="breadcrumb">
                          <Link to={{ pathname: '/dashboard/reports', state: { isNew: true } }}>Report</Link>&nbsp;/&nbsp;<Link to={{ pathname: `/dashboard/reports/${thoughtfullyID}/sessions`, state: { isNew: true } }}>{activity.activityName}</Link>&nbsp;/&nbsp;<Link to={{ pathname: `/dashboard/reports/${thoughtfullyID}/sessions/${sessionID}`, state: { isNew: true } }}>{sessionID}</Link>
                        </div>
                        <Button 
                          icon={<IconLeft/>}
                          className="yellow-black-opaque regular-text minimal-padding"
                          value="BACK"
                          navigateTo={`/dashboard/reports/${thoughtfullyID}/sessions`}
                          
                        />
                      </div>
                    </div>
                        {sessionReport && sessionReport.map((report, resultIndex) => (
                            <div className='report'>
                                <Collapsible title={report['question']} key={report['key']} isOpen={resultIndex==0}>
                                    {
                                        Object.keys(report['answerDistribution']).map((key) => (
                                            <div className="distribution_sec">
                                                <div className="answer">{key}</div>
                                                <div className="strength">Selected this answer: {report['studentCount'][key]}</div>
                                                <div className="progress">
                                                    <ProgressBar completed={report['answerDistribution'][key]} bgColor="#684d94" baseBgColor="#ffffff" borderRadius={0} height={7} isLabelVisible={false} className='progressBar'/>
                                                    <div className="label">{`${formatNumber(report['answerDistribution'][key])}%`}</div>
                                                </div>
                                            </div>
                                        ))
                                        
                                    }
                                    <div className="timeTaken">
                                        <div className="title">Time Taken</div>
                                        {
                                            Object.keys(report['timeTakenDistribution']).map((key) => (
                                                <div className="time_sec">
                                                    <div className="head">
                                                        <div className="sec">{key} seconds</div>
                                                        <div className="per">{formatNumber(report['timeTakenDistribution'][key])}%</div>
                                                    </div>
                                                    <ProgressBar completed={report['timeTakenDistribution'][key]} bgColor="#684d94" baseBgColor="#dddddd" borderRadius={0} height={7} isLabelVisible={false} className='progressBar'/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Collapsible>
                            </div>
                            
                        ))}
                  </div>
                  <Copyright/>
              </div>
            </div>
        </DashboardApp>
  );
}


export default ReportSessionDetail;