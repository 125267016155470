import React, {useEffect, useState} from 'react';
import AppWrapper from '../containers/app-wrapper';
import '../styles/codescreen.scss';
import { Global } from '../common/global';
import { history } from '../components/history'
import ColyseusComponent from '../common/socket';
import { getActivityInfo } from '../common/api';

const CodeScreen = (props) => {
    const [gameID, setGameID]=useState("");
    const [codeStatus, setCodeStatus]=useState(true);
    const saveGameID= function(v){
        setGameID(v.target.value);
    }
    const { checkIfValidCode } = ColyseusComponent({
        "initClient": true
    }); 
    
    const onGameIDConfirm= async ()=>{
        if(gameID.length==="") return false;

        Global.gameID= gameID;
        let roomStatus= await checkIfValidCode();
        setCodeStatus(roomStatus);
        if(roomStatus){
            const res=await getActivityInfo({
                id: gameID
              });
              
              console.log(res.data,'res.data')
              Global.gameMode= res.data.mode;
              Global.deviceType="user";
              Global.studentData.teacherId= res.data.teacherId;
              Global.studentData.activityId=res.data.activityId;

            history.push(`/user/${Global.gameID}/name`);
        }
        
    }
    useEffect(() => {
        if(gameID.length===0){
            document.querySelector("#codeEnter").classList.add("disabled");
        }else{
            document.querySelector("#codeEnter").classList.remove("disabled");
        }

        // return ()=>()
    }, [gameID])
    return (
        <AppWrapper type={"type1"}>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="code_sec">
                {
                    props.userType==="host" && <>
                        <div className="role">
                            <div className="role_icon">
                                <img src="/images/icons/Icon-Tutor.svg" alt="" />
                            </div>
                            <div className="label">HOST</div>
                        </div>
                    </>
                }
                <div className="code_title">
                    Enter the code displayed on the screen
                </div>
                <div className="code_field">
                    <input type="text" name="code" id="code" placeholder='Enter Code' value={gameID} onChange={saveGameID}/>
                </div>
                <div id="codeEnter" className='disabled' onClick={onGameIDConfirm}>
                    ENTER
                </div>
                {
                    !codeStatus && <div className="invalid_sec">Invalid Code</div>
                }
                
            </div>
        </AppWrapper>
    )
}
export default CodeScreen