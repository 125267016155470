import React, {useEffect, useState} from 'react';
import AppWrapper from '../../containers/app-wrapper';
import '../../styles/start.scss';
import StudentProfileIcon from '../../components/studentProfileIcon';
import { Global } from '../../common/global';
import ColyseusComponent from '../../common/socket';
import { useMediaQuery } from 'react-responsive'

const StartScreen = () => {
    const isPortraitScreen = useMediaQuery({
        query: '(max-width: 1024px) and (max-aspect-ratio: 13/10)'
    });
    const [students, setStudents]= useState([
     
    ]);
    const [studentToRemove, setStudentToRemove]= useState(null);
    const [canCountDown, setCanCountDown]= useState(false);
    const [countDown, setCountDown]= useState(10);

    const requestWakeLock= async () =>{
        let wakeLock = null;
        try {
          wakeLock = await navigator.wakeLock.request('screen');
          console.log('Wake Lock is active.');
          wakeLock.addEventListener('release', () => {
            console.log('Wake Lock was released.');
          });
        } catch (err) {
          console.error(`${err.name}, ${err.message}`);
        }
      }
    const startCounter= async ()=>{
        requestWakeLock();
        Global.emitEvent("colyseus:send_events",{
            "message":"start_wait_countdown"
        });
    };
    const stopCounter= () => {
        Global.emitEvent("colyseus:send_events",{
            "message":"stop_wait_countdown"
        });
    };
    const updateStudents=(data)=>{
        setStudents(data);
    };
    const { connect } = ColyseusComponent({
        "initClient": true
    }); 
    const updateWaitTime= (data)=>{
        setCanCountDown(data['wait_time']!==0);
        setCountDown(data['wait_time']);
      };

    const requestUserRemoval= () => {
        Global.emitEvent("colyseus:send_actions",{
            "action_type": "remove_student",
            "student_id": studentToRemove.id
        });
        clearUserRemoval();
    }
    const clearUserRemoval= () => {
        setStudentToRemove(null);
    }
    const showUserToRemove= (data) => {
        setStudentToRemove(data);
    }

    useEffect(()=>{
        const fetchData =  async () => {
            const connectionStats =  await connect();
        };

        fetchData();

        Global.addEventListener("common:game_state_update", updateWaitTime);
        Global.addEventListener("screen:student_join", updateStudents);
        return () => {
            Global.removeEventListener("screen:student_join", updateStudents);
            Global.removeEventListener("common:game_state_update", updateWaitTime);

        }
    }, [])

    return (
        <AppWrapper>
            <div className="bg_cover"><img src="/images/BG.svg" alt="" srcset="" /></div>
            <div className="start_sec">
                <div className="role">
                    <div className="role_icon">
                        <img src="/images/icons/Icon-Tutor.svg" alt="" />
                    </div>
                    <div className="label">HOST</div>
                </div>
                <div className="students">
                    {
                        students.map((student) => (
                            <div className="student" key={student.id}>
                                        <StudentProfileIcon 
                                            id={student.id} 
                                            imgUrl={student.img} 
                                            name={student.name} 
                                            showName={true} 
                                            showPhoto={true}  
                                            extraImgStyles={{width: isPortraitScreen?'8vw':'5vh',height: isPortraitScreen?'8vw':'5vh'}}
                                            extraNameStyles={{fontFamily:'MontserratRegular',fontSize: isPortraitScreen?'3.5vw':'2.25vh', color:'#000000',marginLeft:'10px'}}
                                        />
                                        <div className="close" onClick={showUserToRemove.bind(this, student)}>
                                            <img src="/images/icons/Icon-Cross.svg" alt="" srcset="" />
                                        </div>
                            </div>
                        ))
                    }
                </div>
                <div className="joined_cnt">
                    <div className="label">Users Joined:</div>
                    <div className="cnt">{students.length}</div>
                </div>
                <div id="startBtn" className={!canCountDown?'':`countdown`} onClick={!canCountDown?startCounter:stopCounter}>
                    {!canCountDown?'START':`STARTING IN ${countDown}`}
                    {canCountDown && <div className='tap_int'>Tap to cancel</div>}
                </div>
            </div>
            {
                studentToRemove && <>
                    <div className="remove_confirm">
                        <div className="close" onClick={clearUserRemoval}>
                            <img src="/images/icons/Icon-Cross.svg" alt="" srcset="" />
                        </div>
                        <div className="container">
                            <div className="profile_img">
                                <StudentProfileIcon 
                                    id={studentToRemove.id} 
                                    imgUrl={studentToRemove.img} 
                                    name={studentToRemove.name} 
                                    showName={true} 
                                    showPhoto={true} 
                                    extraImgStyles={{marginBottom:'5vw',width:'100%',height:'100%', backgroundColor:'#684d94'}} 
                                    extraProfileStyles={{width:'100%', borderRadius:'10vw'}} 
                                    extraNameStyles={{fontSize:isPortraitScreen?'7vw':'2.5vh'}}
                                />
                            </div>
                            <div className="picked_head">Are you sure you want to remove this user?</div>
                            <div id="removeConfirm" onClick={requestUserRemoval}>
                               CONFIRM
                            </div>
                        </div>
                    </div>
                </>
            }
        </AppWrapper>
    )
}
export default StartScreen