import React, { useEffect } from 'react';
import './style.scss';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Ads = ({ recentThoughtfullys, ...props }) => {

  useEffect(() => {

  }, []);

  return (
    <div className={`ads_right_area ${props.darkModeClass}`}>
      <div className="top">
        <Carousel showThumbs={false} showArrows={false} showStatus={false} interval={3000} autoPlay={true} infiniteLoop={true}>
          <div>
            <a href='https://jalanjourney.com/' target='_blank'><img src="/images/dashboard/ads/Banner01.png" alt="Ad1" /></a>
          </div>
          <div>
            <a href='https://jalanjourney.com/' target='_blank'><img src="/images/dashboard/ads/Banner02.png" alt="Ad2" /></a>
          </div>
          <div>
            <a href='https://jalanjourney.com/' target='_blank'><img src="/images/dashboard/ads/Banner03.png" alt="Ad3" /></a>
          </div>
          <div>
            <a href='https://jalanjourney.com/' target='_blank'><img src="/images/dashboard/ads/Banner04.png" alt="Ad4" /></a>
          </div>
        </Carousel>
      </div>
{/* Google AdSense Section */}
{/* <div className="google_adsense">
          <ins className="adsbygoogle"
            style={{ display: "block", textAlign: "center" }}
            data-ad-format="fluid"
            data-ad-layout="in-article"
            data-ad-client="ca-pub-0123456789101112"
            data-ad-slot="9876543210">
          </ins>
        </div> */}
      <div className="bottom">
        <Link to={{ pathname: '/dashboard/help' }} className=""><img src="/images/dashboard/BannerPro.png" alt="Upgrade to Pro" /></Link>
        <div className="label">Interested? Simply drop us an email! info@jalanjourney.com</div>
      </div>
    </div>
  );
}

export default Ads;
