import React, { useState, useEffect } from 'react'
import './style.scss'


const ScaleMockup = (props) => {
  const [darkModeClass, setdarkModeClass ]= useState(
    localStorage.getItem('darkMode') == 'true'?'darkMode':''
  )
  useEffect(() => {
  
    return () => {
    };
  }, []);
  return (
    <div id="scale_mockup" class={darkModeClass?"dark":""}>
        <div id="live_popup_close" className="close" onClick={props.closeMockup.bind(this,false)}></div>
        <div className="container">
            <div className="title">USER RESPONSE FORMAT*</div>
            <div className="title_sub">6-Point Agreement Scale<br/>{'('}Strongly Agree {'->'} Strongly Disagree {')'}</div>
            <div className="img"><img src="/images/dashboard/Thoughtfully_Point_Scale_Mockup.png" alt="" srcset="" /></div>
            <div className="info">This is how responses appear on the user's device.</div>
        </div>
        <div className="info2">*We are exploring the possibility of adding more response<br/>formats in the future. Let us know what you'd like to see!</div>
    </div>
  )
}

export default ScaleMockup;