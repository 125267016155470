import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header'
import Footer from '../components/footer'
import { Global } from '../common/global';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";


function App({ children, ...props}) {
  const isLobby= window.location.pathname.indexOf('lobby')!==-1;
  const isScreen= window.location.pathname.indexOf('screen')!==-1;
  const isThoughts=  window.location.pathname.indexOf('thought')!==-1;
  const [canCountDown, setCanCountDown]= useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const updateWaitTime= (data)=>{
    setCanCountDown(data['question_time']!==0);
  };

  useEffect(() => {
    
    Global.addEventListener("common:game_state_update", updateWaitTime);
    return () => {
      Global.removeEventListener("common:game_state_update", updateWaitTime);
    }
  }, [])
  return (
    <>
    <div className={!isScreen?"extraPadd app":"app"}>
      {
        (props.addHeader===undefined || props.addHeader!==undefined && props.addHeader) && <Header extraClass={props.extraClass} {...props.gameData} {...props}/>
      }
      <main>{children}</main>
      {
        (props.addFooter===undefined || props.addFooter!==undefined && props.addFooter) && (!isLobby || !isScreen) && <Footer extraClass={ isScreen?props.extraClass:'normal'} {...props} canCountDown={canCountDown} isThoughts={isThoughts}/>
      }
    </div>
     <div>
     {/* <CookieConsent
       location="bottom"
       buttonText="Accept All"
       declineButtonText="Decline"
       enableDeclineButton
       onAccept={() => console.log("User accepted cookies")}
       onDecline={() => console.log("User declined cookies")}
       cookieName="cookieConsent"
       expires={365}
       style={{ background: "#222" }}
       buttonStyle={{ background: "#f1c40f", color: "#222" }}
       declineButtonStyle={{ background: "#555", color: "#fff" }}
     >
       We use cookies to improve your experience.{" "}
       <button onClick={() => setShowSettings(true)} style={{ background: "none", border: "none", color: "#f1c40f", textDecoration: "underline", cursor: "pointer" }}>
         Manage settings
       </button>
     </CookieConsent> */}

     {/* {showSettings && <CookieSettings onClose={() => setShowSettings(false)} />} */}
   </div>
    </>
  )
}

function CookieSettings({ onClose }) {
  return (
    <div style={styles.modal}>
      <div style={styles.content}>
        <h2>Cookie Preferences</h2>
        <p>Customize your cookie settings below:</p>
        <label>
          <input type="checkbox" defaultChecked disabled /> Essential Cookies (Required)
        </label>
        <label>
          <input type="checkbox" id="analytics" /> Analytics Cookies
        </label>
        <label>
          <input type="checkbox" id="ads" /> Advertising Cookies
        </label>
        <button onClick={() => { 
          localStorage.setItem("analyticsCookies", document.getElementById("analytics").checked);
          localStorage.setItem("adsCookies", document.getElementById("ads").checked);
          onClose();
        }} style={styles.saveButton}>
          Save Preferences
        </button>
        <button onClick={onClose} style={styles.closeButton}>Cancel</button>
      </div>
    </div>
  );
}

const styles = {
  modal: { position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0,0,0,0.5)", display: "flex", justifyContent: "center", alignItems: "center" },
  content: { background: "#fff", padding: "20px", borderRadius: "10px", textAlign: "center" },
  saveButton: { background: "#f1c40f", color: "#222", padding: "10px", marginTop: "10px", cursor: "pointer" },
  closeButton: { background: "#ccc", padding: "10px", marginTop: "10px", cursor: "pointer", marginLeft: "10px" },
};
App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default App
